/* You can add global styles to this file, and also import other style files */
@use "css-fx-layout" as fx;

@import 'ngx-toastr/toastr';

@import 'material-icons/iconfont/material-icons.scss';

@font-face {
    font-family: 'digital7';
    src: url('assets/fonts/digital.woff2') format('woff2'),
        url('assets/fonts/digital.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



// If you choose to use CSS class selectors:
@include fx.class-selectors;
@include fx.gap-class-selectors(1, 16, px em);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.tabcontainer {
  padding: 20px;
}

.stdsvg {
  width: 20px;
  height: 20px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 100;
}

.mat-dialog-container {
  background-color: ivory;
}

tr:hover {
  background: #e1e1e1;
}

.field-full-width {
  width: 100%;
}

.mat-column-typ {
  width: 50px;
}

.mat-column-card {
  width: 100px;
}

.mat-column-valid {
  width: 100px;
}

.mat-column-name {
  width: 30%;
}

.mat-column-guest {
  width: 30%;
}

.mat-toolbar.mat-primary {
  background: #7db53f8c;
}

.tabcontainer {
  padding: 20px;
  background: rgba(255, 255, 255, 0.3);

  @media screen and (min-width: 1000px) {
    margin: 20px;
  }
}

.tabtitle {
  color: white;
}

.toast-top-right {
  top: 70px;
}

.error_message {
  font-weight: bold;
  color: red;
}

.color_red {
  color: red;
  background: black;
  border-radius: 50%;
}

.allow-cr {
  white-space: pre;
}

.polcontainer {
  margin-top: 5px;
  border-top: #eee solid 5px;
  padding-bottom: 5px;

  width: 170px;

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 4px;
  }

  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
  padding-bottom: 5px;
  background: rgba($color: black, $alpha: 0.2);
  height: 60px;
  color: white;
  cursor: pointer;
  transition: border-color 0.4s,
  background-color 0.4s,
  color 0.4s;
  border-radius: 5px;
}

.polcontainer:hover {
  border-color: gold;
}

.iconpol {
  width: 20px;
  height: 20px;
  padding: 2px;
  border: transparent solid 1px;
  border-radius: 2px;
  cursor: pointer;

  &-selected {
    border: red solid 1px;
  }
}

.bulletheader {
  width: 40px;
  text-align: center;
  font-size: 12px;
}

.bullet {
  border-radius: 4px;
  margin: 4px;
  padding: 2px;
  font-size: 12px;
  color: white;

  &--Arm {
    background-color: orange;
  }

  &--Alarm {
    background-color: red;
  }

  &--OpenTrip {
    background-color: blue;
  }

  &--Tamper {
    background-color: violet;
  }

  &--Trouble {
    background-color: yellow;
  }

  &--Lost {
    background-color: darkred;
  }

  &--LowBatterytrouble {
    background-color: gray;
  }

  &--Bypass {
    background-color: orangered;
  }

  &--hourzone24 {
    background-color: green;
  }

  &--Duress {
    background-color: green;
  }

  &--FalseCoed {
    background-color: green;
  }

  &--Fire {
    background-color: green;
  }

  &--Panic {
    background-color: green;
  }

  &--Medic {
    background-color: green;
  }

  &--HomeStay {
    background-color: green;
  }

  &--ReadytoArm {
    background-color: green;
  }

  &--ExitOpenzoneisopen {
    background-color: green;
  }

  &--Resetrequired {
    background-color: orange;
  }

  &--ConnectedToCloud {
    background-color: green;
  }

  &--disabled {
    background-color: lightgray;
    color: gray;
  }
}

.bulletcontainer {
  display: flex;
  flex-wrap: wrap;

  align-items: center;
}

.solidborder {
  border-bottom: gray solid 1px;
}

.alahead {
  margin-left: 200px;
  display: flex;
}



.alahead span {
  display: block;
  width: 200px;
  margin: 4px;
  padding: 0;
  text-align: center;
  font-size: 12px;
}

.alarm-label {
  width: 200px;
  margin: 4px;
  padding: 0;
  text-align: left;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/*
.dialog-content mat-dialog-title {
  flex-grow: 1;
}

.dialog-content mat-dialog-actions {
  flex-grow: 1;
}

.dialog-content mat-dialog-content {
  flex-grow: 2;
}
*/
.spacer {
  flex-grow: 2;
}

.timepicker-overlay {
  z-index: 10000 !important;
}

.spinner {
  &--small {
    width: 32px;
    height: 32px;
    margin: 4px;
  }
}

@keyframes alarm_animation {
  0% {color: red;}
  50% {color: yellow;}
  100% {color: red;}

}


